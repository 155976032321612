<template>
	<form v-if="!loading" ref="form" id="dataform" :class="!getTimelineInContext && !consumer ? 'content-container' : null">
		<template v-if="task && Array.isArray(task.widget_meta)">
			<div class="task-preview mt-4 mb-4" v-for="(w, i) in task.widget_meta" :key="i">
				<div v-if="w.widget.data">
					<div v-if="w.widget.data.type == 'text'" class="widget-item">
						<div v-html="w.widget.data.content.html" class="text-widget"></div>
					</div>

					<div class="widget-item" v-else-if="w.widget.data.type == 'questionnaire'">
						<div v-for="(c, index) in w.widget.data.content" :key="index" class="w-75 mx-auto">
							<div v-if="c.type == 'text'" class="row mb-1">
								<label class="w-50">{{ c.label }}</label>
								<input
									type="text"
									:name="w.widget.data.name + '[' + c.label + ']'"
									class="form-control form-control-sm w-50"
									:placeholder="c.placeholder"
									v-model="c.value"
								/>
							</div>
							<div v-if="c.type == 'checkbox'" class="row">
								<label class="w-50">{{ c.label }}</label>
								<div class="form-group w-50">
									<div v-for="option in c.options" :key="option.id" class="form-check form-check-inline">
										<input
											class="form-check-input"
											type="checkbox"
											:name="w.widget.data.name + '[' + c.label + '][]'"
											:value="option.value"
										/>
										<label class="form-check-label">{{ option.text }}</label>
									</div>
								</div>
							</div>
							<div v-if="c.type == 'dropdown'" class="row mb-1">
								<label class="w-50">{{ c.label }}</label>
								<b-form-select
									size="sm"
									:name="w.widget.data.name + '[' + c.label + ']'"
									:options="c.options"
									:value="c.value"
									class="w-50"
								></b-form-select>
							</div>
						</div>
					</div>

					<div class="widget-item" v-else-if="w.widget.data.type == 'video'">
						<vue-plyr>
							<div class="plyr__video-embed">
								<iframe :src="w.widget.data.content" allowfullscreen allowtransparency allow="autoplay"></iframe>
							</div>
						</vue-plyr>
					</div>

					<div class="widget-item" v-else-if="w.widget.data.type == 'download'">
						<div class="download-widget">
							<p>{{ w.widget.data.content.message }}</p>
							<a class="back-btn" @click="downloadFile(w.widget.data.content.url, w.widget.data.content.fileName)"
								><i class="fas fa-download"></i>{{ w.widget.data.content.fileName }}</a
							>
						</div>
					</div>

					<div class="widget-item" v-else-if="w.widget.data.type == 'photo'">
						<div class="photo-widget">
							<img :src="w.widget.data.content.url" :aria-alt="w.widget.data.content.text || 'Photo'" />
						</div>
					</div>
					<div class="widget-item" v-else-if="w.widget.data.type == 'delay'">
						<p class="delay-widget">
							<i class="far fa-clock"></i> This task will be sent
							{{ w.widget.data.content.substring('one') ? 'in' : 'on' }}
							{{ w.widget.data.content }}.
						</p>
					</div>

					<div class="widget-item" v-else-if="w.widget.data.type == 'upload'">
						<label :for="'file-upload-' + w.widget.data.name">{{
							w.widget.data.content && w.widget.data.content.message ? w.widget.data.content.message : 'Upload File:'
						}}</label>
						<b-form-file
							:id="'file-upload-' + w.widget.data.name"
							:name="w.widget.data.name"
							size="sm"
							variant="light"
						></b-form-file>
					</div>

					<div class="widget-item" v-else-if="w.widget.data.type == 'calendar'">
						<div class="calendar-widget">
							<a class="invite-link mb-2" :href="w.widget.data.content.url || '#'" target="_blank"
								><i class="far fa-calendar-plus"></i> {{ w.widget.data.content.invite.title }}</a
							>
							<label class="mb-2"><strong>Date: </strong> {{ convertDate(w.widget.data.content.invite.date) }}</label>
							<label class="mb-1"
								><strong>From: </strong>
								{{ convertTime(w.widget.data.content.invite.time_start) }}
								<strong>To: </strong>
								{{
									convertDuration(w.widget.data.content.invite.duration, w.widget.data.content.invite.time_start) ||
										'No end time specified'
								}}</label
							>
							<label
								class="invitees mb-2"
								v-if="
									Array.isArray(w.widget.data.content.invite.invitees.length) &&
										w.widget.data.content.invite.invitees.length > 0
								"
								><strong class="mr-2">Invitees: </strong> {{ w.widget.data.content.invite.invitees.join(', ') }}</label
							>
							<label class="mb-2" v-if="w.widget.data.content.invite.message">{{ w.widget.data.content.invite.message }}</label>
						</div>
					</div>

					<div v-else class="widget-item">
						{{ w.widget.data.content }}
						<hr />
					</div>
				</div>

				<div class="widget-item" v-if="!w.widget.data">
					{{ w.widget.title }}
					<p>This widget has not been edited</p>
				</div>
			</div>
			<div class="w-75 mx-auto" v-if="!consumer">
				<button class="btn-blue w-100" @click.stop.prevent="sendFormData()">
					Submit
				</button>
			</div>
		</template>
	</form>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';
import VuePlayer from '../VuePlayer';
import { mapGetters, mapActions } from 'vuex';
import { google } from 'calendar-link';
Vue.use(VuePlayer);

export default {
	props: ['widgetMeta', 'consumer'],
	data() {
		return {
			widgets: null,
			task: null,
			submitting: false,
			loading: false
		};
	},
	async mounted() {
		try {
			this.loading = true;
			const timelineID = this.$route.params?.timelineID;

			if (!timelineID || !this.getTimelineInContext?.timeline_id != timelineID) {
				if (timelineID || !this.getTimelineInContext?.timelineID) {
					await this.$store.dispatch('getTimeline', timelineID || this.getTimelineInContext?.timeline_id);
				}

				if (!this.getTimelineInContext) {
					// bail - should not be here.
					return this.$router
						.push({
							name: 'ClientTimelines',
							params: { clientID: this.getClientInContext.client_id }
						})
						.catch(() => {});
				}
			}

			// just refetch the task
			await this.$bus.$emit('breadcrumbPush', {
				text: this.$attrs.taskUUID,
				to: {
					name: 'ManagerTimelineTask',
					params: this.$attrs
				}
			});
			this.task = await this.$store.dispatch('getTimelineTask', {
				timelineID: this.getTimelineInContext.timeline_id,
				consumerID: this.getTimelineInContext.consumer_id,
				taskGroupTaskID: this.$attrs.taskUUID
			});

			if (this.task.widget_meta && Array.isArray(this.task.widget_meta)) {
				this.task.widget_meta.forEach(async (widget, i) => {
					if (widget.widget.data && widget.widget.type == 'Calendar') {
						let link = await this.generateICSFile(widget.widget.data.content.invite);
						if (link.errors) {
							return this.$store.dispatch('createErrors', link.errors);
						}
						this.task.widget_meta[i].widget.data.content.url = link;
					}
				});
			}
		} finally {
			this.loading = false;
		}
	},
	computed: {
		...mapGetters(['getTimelineInContext', 'getUserIsClientAdmin', 'getUserIsManager', 'getClientInContext'])
	},
	methods: {
		async downloadFile(url, label) {
			axios
				.get(url, { responseType: 'blob' })
				.then((response) => {
					const blob = new Blob([response.data], { type: 'application/pdf' });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = label;
					link.click();
					URL.revokeObjectURL(link.href);
					this.$bvToast.toast('Your file has been downloaded.', {
						title: 'Success',
						variant: 'info',
						autoHideDelay: 5000
					});
				})
				.catch((e) => {
					this.$bvToast.toast('There was an error downloading your file. Please ensure your file is an accepted file type.', {
						title: 'Error',
						variant: 'danger',
						autoHideDelay: 5000
					});
				});
		},
		async generateICSFile(invite) {
			let { title, date, time_start, duration, invitees, location, message } = invite;

			if (moment(date, true).isValid() && moment(time_start, true).isValid()) {
				let guest;

				const event = {
					title: title,
					description: message,
					start: time_start,
					location: location,
					guests: invitees
				};

				if (duration) {
					event.duration = [duration, 'm'];
				}

				const url = google(event);

				if (!url) {
					return {
						errors: 'Unable to create calendar link, please try again later.'
					};
				}

				return url;
			}

			return {
				errors: 'Unable to create calendar link: unsupported date + time values'
			};
		},
		convertDate(date) {
			if (date && moment(date, true).isValid()) {
				const strDate = moment(date).format('MMMM DD, YYYY');
				return strDate;
			}
			return date;
		},
		convertDuration(duration, time_start) {
			if (duration && time_start) {
				if (moment(time_start, 'HH:mm:ss', true).isValid() || moment(time_start, true).isValid()) {
					let strTime = moment
						.utc(time_start)
						.local()
						.add(duration, 'minutes')
						.format('h:mm A');
					return strTime;
				}
				return `${duration} minutes from ${time_start}`;
			}
		},
		convertTime(time) {
			if (time && (moment(time, 'HH:mm:ss', true) || moment(time, true).isValid())) {
				let strTime = moment
					.utc(time)
					.local()
					.format('h:mm A');
				return strTime;
			}
			return time;
		},
		async sendFormData() {
			if (this.submitting == (this.submitting = true)) {
				return false;
			}
			try {
				let inputMeta = {};
				let errors = [];
				let currentFormData = new FormData(this.$refs['form']);
				let entryIterator = currentFormData.entries();
				let next = entryIterator.next();
				while (next && next.done === false) {
					let [key, value] = next.value;
					if (value && typeof value == 'object' && value.constructor.name == 'File') {
						// only 1 file per name, NO ARRAY
						if (value.size && value.size > 0) {
							// sized file found
							let bytes = new Uint8Array(await value.arrayBuffer());
							// each base64 value is 6 bits, so it will round up 6 additional bits. basically we need to check an "inflated" file size
							if (bytes.length > 5000000) {
								errors.push(`File ${key} is too large, please make sure your file is 5 MB or less`);
								next = entryIterator.next();
								continue;
							}
							let binary = '';
							for (let i = 0; i < bytes.byteLength; i++) {
								binary += String.fromCharCode(bytes[i]);
							}

							// type = filetype, encoded base64
							inputMeta[key] = {
								type: value.type,
								payload: btoa(binary)
							};
						}
					} else {
						// Reflect.has in favor of: object.hasOwnProperty(key)
						if (!Reflect.has(inputMeta, key)) {
							inputMeta[key] = value;
						} else {
							// onvert value to array type.
							if (!Array.isArray(inputMeta[key])) {
								inputMeta[key] = [inputMeta[key]];
							}
							inputMeta[key].push(value);
						}
					}
					next = entryIterator.next();
				}

				if (this.getTimelineInContext) {
					this.$bus.$emit('submitInputMeta', inputMeta);
				} else {
					if (
						await this.$store.dispatch('updateInputMeta', {
							timelineID: this.getTimelineInContext.timeline_id,
							consumerID: this.getTimelineInContext.consumer_id,
							timelineTaskGroupTaskID: this.task.timeline_task_group_task_id,
							inputMeta: inputMeta,
							completed: true
						})
					) {
						await this.$store.dispatch('createAlerts', 'Your task has been completed!');
						this.$router.push({ name: 'Home' }).catch(() => {});
					}
				}
			} finally {
				this.submitting = false;
			}
		}
	}
};
</script>
